<template>
    <Main :qrcode="true" class="background">
        <Header checked="cart"></Header>
        <Block class="content">
            <div class="middle">
                <div class="title-group">
                    <div class="weight1">
                        <el-checkbox v-model="checkedAll" class="checked-item" @change="all">{{$t('cart.selectAll')}}</el-checkbox>
                    </div>
                    <div class="weight2">{{$t('cart.productName')}}</div>
                    <div class="weight3">{{$t('cart.information')}}</div>
                    <div class="weight4">{{$t('cart.operation')}}</div>
                </div>
                <div class="list">
                    <div v-for="(item, index) in list" :key="index" class="list-item">
                        <div class="weight1 pic-group">
                            <el-checkbox v-model="item.checked" @change="checkedOne" class="checked-item"></el-checkbox>
                            <div class="good-pic">
                                <el-image class="pointer" :src="item.main_picture" style="width:100%;height:100%" fit="contain" lazy @click.native="jump('/goodsinfo', {id: item.toy_id})"
                                :title="getLanguageData({zh: item.name, en: item.name_en}) + '（' + item.article_number + '）'"></el-image>
                            </div>
                        </div>
                        <div class="weight2 name-group">
                            <div class="good-name pointer" @click="jump('/goodsinfo', {id: item.toy_id})" :title="getLanguageData({zh: item.name, en: item.name_en})">{{getLanguageData({zh: item.name, en: item.name_en})}}</div>
                            <div class="good-num">NO: {{item.article_number}}</div>
                        </div>
                        <div class="weight3 info-group">
                            <div v-if="getLanguageData({zh: item.packing, en: item.packing_en})">{{$t('cart.packaging')}}：{{getLanguageData({zh: item.packing, en: item.packing_en})}}</div>
                            <div v-if="item.length">{{$t('cart.productSize')}}：{{item.length}}*{{item.width}}*{{item.height}} cm</div>
                            <div v-if="item.pack_length">{{$t('cart.packingSize')}}：{{item.pack_length}}*{{item.pack_width}}*{{item.pack_height}} cm</div>
                            <div v-if="item.carton_cubic_meter">{{$t('cart.outsideBoxVolume')}}：{{item.carton_cubic_meter}} cbm</div>
                            <div v-if="item.carton_cubic_feet">{{$t('cart.cartonVolume')}}：{{item.carton_cubic_feet}} cuft</div>
                            <div v-if="item.carton_length">{{$t('cart.cartonSize')}}：{{item.carton_length}}*{{item.carton_width}}*{{item.carton_height}} cm</div>
                            <div v-if="item.carton_quantity">{{$t('cart.quantity')}}：{{item.carton_quantity}}</div>
                            <div v-if="item.inbox_count">{{$t('cart.innerBoxVolume')}}：{{item.inbox_count}} cbm</div>
                            <div v-if="item.gross_weight">{{$t('cart.grossNetWeight')}}：{{item.gross_weight}}/{{item.net_weight}} KGS</div>
                        </div>
                        <div class="weight4 botton-group">
                            <div class="inquiry-botton" @click="inquiry([item])">{{$t('cart.inqueryButton')}}</div>
                            <div class="delete-botton" @click="del([item.cart_id])">{{$t('cart.deleteButton')}}</div>
                        </div>
                    </div>
                    <div v-if="list.length == 0" class="empty">
                        <img :src="EmptyImg">
                        <div class="empty-tips">{{$t('cart.emptyData')}}</div>
                    </div>
                </div>
            </div>
        </Block>
        <Block>
            <div class="bottom">
                <div class="bottom-left">
                    <div>
                        <el-checkbox v-model="checkedAll" class="checked-item" @change="all">{{$t('cart.selectAll')}}</el-checkbox>
                    </div>
                    <div class="delete" @click="batDel">
                        <img :src="require('@/assets/img/icon/delete.png')" />
                        <div class="delete-text">{{$t('cart.batDeleteButton')}}</div>
                    </div>
                </div>
                <div class="bottom-right">
                    <div class="gather">{{$t('cart.gatherText1')}} <span class="red">{{total}}</span> {{$t('cart.gatherText2')}} <span class="red">{{selectList.length}}</span> {{$t('cart.gatherText3')}}</div>
                    <div class="submit-button" @click="batInquery">{{$t('cart.batInqueryButton')}}</div>
                </div>
            </div>
        </Block>
        <bat-inquiry-dialog v-model="dialogVisible" :list="inquiryList" @refurbish="refurbish"></bat-inquiry-dialog>
    </Main>
</template>

<script>
import Main from '@/components/layout2/layout2-main.vue';
import Header from '@/components/layout2/layout2-header.vue';
import Block from '@/components/layout2/layout2-block.vue'
import BatInquiryDialog from './components/bat-inquiry-dialog.vue';
import { list, del } from '@/api/cart.js';
import { getLanguageData,jump } from "@/libs/tools.js";
import EmptyImg from '@/assets/img/empty.png'

export default {
    name: 'cart',
    components: {
        Main,
        Header,
        Block,
        BatInquiryDialog,
    },
    data() {
        return {
            checkedAll: false,
            list: [],
            total: 0,
            loading: false,
            dialogVisible: false,
            inquiryList: [],
            EmptyImg: EmptyImg
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        getLanguageData,
        jump,
        refurbish(){
            this.getList();
            this.$store.dispatch('cart/getNum');
        },
        getList() {
            this.loading = true;
            list({}).then(res => {
                if (res.code == 0) {
                    this.list = res.data.data;
                    this.total = res.data.count;
                    for (let i = 0; i < this.list.length; i++) {
                        this.$set(this.list[i], 'checked', false);
                    }
                } else {
                    this.$message.error(res.msg);
                }
            }).finally(() => {
                this.loading = false;
                this.checkedAll = false;
            })
        },
        all(val) {
            if (val == true) {
                for (let i = 0; i < this.list.length; i++) {
                    this.$set(this.list[i], 'checked', true);
                }
            } else {
                for (let i = 0; i < this.list.length; i++) {
                    this.$set(this.list[i], 'checked', false);
                }
            }
        },
        checkedOne(){
            let ret = true;
            for(let i=0; i< this.list.length; i++){
                if(this.list[i].checked == undefined || this.list[i].checked == false){
                    ret = false;
                    break;
                }
            }
            this.checkedAll = ret;
        },
        batDel(){
            if(this.selectList.length > 0){
                this.del(this.selectList);
            } else {
                this.$message.error(this.$t('common.leastSelectOne'))
            }
        },
        del(ids){
            this.$confirm(this.$t('cart.delete_cue'), this.$t('common.cue'), {
                confirmButtonText: this.$t('common.confirm_text'),
                cancelButtonText: this.$t('common.cancel_text'),
                customClass: 'operate-login-cue',
                center: true
            }).then(() => {
                del({ id: ids }).then(res => {
                    if (res.code == 0) {
                        this.getList();
                        this.$message.success(res.msg);
                        this.$store.dispatch('cart/getNum');
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }).catch(() => {
                // reject(new Error('cancel'));
            });
        },
        inquiry(list){
            this.inquiryList = list;
            this.dialogVisible = true;
        },
        batInquery(){
            let list = [];
            for (let i = 0; i < this.list.length; i++) {
                if(this.list[i]['checked'] != undefined && this.list[i]['checked'] == true){
                    list.push(this.list[i]);
                }
            }
            if(list.length > 0){
                this.inquiry(list);
            } else {
                this.$message.error(this.$t('common.leastSelectOne'));
            }
        }
    },
    computed:{
        //选中的id数组
        selectList(){
            let ids = [];
            for(let i=0; i< this.list.length; i++){
                if(this.list[i].checked != undefined && this.list[i].checked == true){
                    ids.push(this.list[i].cart_id);
                }
            }
            return ids;
        }
    }
}
</script>

<style>
</style>
<style lang="less" scoped>
.pointer{
    cursor: pointer;
}
.background {
    width: 100%;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.content {
    margin-top: 20px;
    flex: 1;
    overflow: hidden;
    box-sizing: border-box;
}
.middle {
    background: #fff;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}
.bottom {
    padding: 0 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 68px;
    background: #fff;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    justify-content: space-between;
    .bottom-left {
        display: flex;
        .delete {
            display: flex;
            align-items: center;
            margin-left: 30px;
            color: #f33636;
            cursor: pointer;
            img {
                height: 16px;
                width: 16px;
            }
            .delete-text {
                margin-left: 6px;
            }
        }
    }
    .bottom-right {
        display: flex;
        align-items: center;
        .red {
            color: #3053A3;
        }
        .submit-button {
            padding: 0 24px;
            box-sizing: border-box;
            margin-left: 67px;
            margin-right: 38px;
            min-width: 138px;
            height: 40px;
            background: #3053A3;
            color: #ffffff;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
::v-deep {
    .el-checkbox {
        font-size: 20px;
        color: #999999;
        &:hover {
            border-color: #3053A3 !important;
            color: #3053A3 !important;
            .el-checkbox__inner {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }
        .el-checkbox__input {
            .el-checkbox__inner {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            &.is-checked {
                .el-checkbox__inner {
                    background-color: #3053A3;
                    border-color: #3053A3;
                }
                + .el-checkbox__label {
                    color: #3053A3 !important;
                }
            }
            .el-checkbox__inner {
                &:hover {
                    border-color: #3053A3;
                }
            }
            .el-checkbox__inner::after {
                left: 7px;
                top: 5px;
            }
            &.is-focus {
                .el-checkbox__inner {
                    border-color: #3053A3;
                }
            }
        }
    }
}
.title-group {
    margin: 0 30px;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #999999;
    border-bottom: 1px solid #dddddd;
    flex-shrink: 0;
}
.weight1 {
    width: 158px;
}
.weight2 {
    width: 166px;
}
.weight3 {
    width: 570px;
}
.weight4 {
    width: 178px;
    text-align: center;
    padding-right: 35px;
    box-sizing: border-box;
}
.list {
    flex: 1;
    padding: 0 30px;
    overflow: overlay;
}
.list-item {
    padding: 19px 0 20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dddddd;
    box-sizing: border-box;
    &:first-child {
        border-top: 0;
    }
}
.pic-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .good-pic {
        width: 112px;
        height: 84px;
    }
}
.checked-item {
    margin-left: 10px;
}
.name-group {
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    .good-name {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: #3c3c3c;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover{
            color:#3053A3
        }
    }
    .good-num {
        margin-top: 10px;
        font-size: 12px;
        color: #666666;
    }
}
.info-group {
    display: flex;
    font-size: 12px;
    color: #666666;
    flex-wrap: wrap;

    div {
        width: 33%;
    }
    div:not(:nth-child(3n)) {
        margin-right: 0.5%;
    }
}
.botton-group {
    display: flex;
    align-items: center;
}
.inquiry-botton {
    width: 64px;
    height: 32px;
    border: 1px solid #3053A3;
    border-radius: 4px;
    font-size: 14px;
    color: #3053A3;
    line-height: 32px;
    cursor: pointer;
}
.delete-botton {
    margin-left: 10px;
    width: 64px;
    height: 32px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 14px;
    color: #666666;
    line-height: 32px;
    cursor: pointer;
}
.empty{
    width: 240px;
    font-size: 14px;
    color: #666666;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    margin-left: -120px;
    .empty-tips{
        margin-top: 16px;
    }
}
</style>