<template>
    <el-popover placement="bottom-start" width="320" trigger="manual" v-model="dialogVisible">
        <div class="title">{{$t('bat_inquiry_dialog.batSetRemarkTitle')}}</div>
        <el-form ref="form" :validate-on-rule-change="false" :model="info" :show-message="false" label-position="left" size="small">
            <el-form-item prop="inquiry_count">
                <div class="line">
                    <div class="label">{{$t('bat_inquiry_dialog.batSetRemarkLabel')}}</div>
                    <el-input class="input" v-model="info.inquiry_content" :maxlength="100" clearable></el-input>
                </div>
            </el-form-item>
            <div class="operate-button-group">
                <div class="operate-button operate-button-1" @click="dialogVisible=false">{{$t('common.cancel_text')}}</div>
                <div class="operate-button operate-button-2" @click="save">{{$t('common.confirm_text')}}</div>
            </div>
        </el-form>
        <template slot="reference">
            <slot></slot>
        </template>
    </el-popover>
</template>

<script>
import { getLanguageData, isEmpty, deepCopy } from "@/libs/tools.js";
import { getLanguage } from "@/lang/index.js";

export default {
    name: 'bat-set-remark-popover',
    components: {

    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            lang: getLanguage(),
            info: {

            },
        }
    },
    mounted() {

    },
    methods: {
        isEmpty,
        getLanguageData,
        save() {
            if(this.info.inquiry_content == undefined){
                return false;
            }
            this.dialogVisible = false;
            this.$emit("save", this.info.inquiry_content);
        },
    },
    computed: {
        dialogVisible: {
            set(val) {
                this.$emit('input', val);
            },
            get() {
                return this.value;
            }
        },
    },
    watch: {
        dialogVisible: {
            immediate: true,
            handler: function (val) {
                if (val == false && this.$refs.form != undefined) {
                    this.$refs.form.resetFields();
                }
            }
        },
    }
}
</script>
<style lang="less" scoped>
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/deep/ .el-form-item {
    margin-bottom: 0;
}
.operate-button-group {
    margin-top: 18px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .operate-button {
        font-size: 14px;
        font-weight: 400;
        min-width: 76px;
        height: 32px;
        padding: 0;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .operate-button-1 {
        color: #999999;
        background: #f6f6f6;
    }
    .operate-button-2 {
        margin-left: 8px;
        color: #ffffff;
        background: #3053A3;
    }
}
.el-popper {
    .title {
        line-height: 19px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        padding-bottom: 9px;
        border-bottom: 1px solid #ededed;
    }
    .line {
        display: flex;
        height: 32px;
        margin-top: 18px;
        .label {
            height: 32px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #666666;
            margin-right: 10px;
        }
        .input {
            flex: 1;
            /deep/ .el-input__inner {
                height: 32px;
                background: #ffffff;
                border: 1px solid #cccccc;
                border-radius: 2px;
                text-align: left;
            }
        }
    }
}
</style>
