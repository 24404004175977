<template>
    <el-dialog class="inquiry-dialog" :title="$t('bat_inquiry_dialog.title')" :show-close="false" :destroy-on-close="true" :close-on-click-modal="false" :append-to-body="true" :visible.sync="dialogVisible" width="800px">
        <el-form ref="form" :validate-on-rule-change="false" :model="form" v-loading="loading" :show-message="false" label-position="left" size="small">
            <div class="inquiry-main">
                <div class="button-group">
                    <BatSetCountPopver v-model="batSetCountPopverVisible" @save="batSetCount">
                        <div class="button" @click="batSetCountPopverVisible=true;batSetRemarkPopverVisible=false;">{{$t('bat_inquiry_dialog.batSetCount')}}</div>
                    </BatSetCountPopver>
                    <BatSetRemarkPopver v-model="batSetRemarkPopverVisible" @save="batSetRemark">
                        <div class="button" @click="batSetRemarkPopverVisible=true;batSetCountPopverVisible=false;">{{$t('bat_inquiry_dialog.batSetRemark')}}</div>
                    </BatSetRemarkPopver>
                </div>
                <div class="table">
                    <el-table ref="table" v-loading="loading" class="table-border" :data="form.tableData" height="340px" header-cell-class-name="table-header" cell-class-name="table-border" border style="width: 100%">
                        <el-table-column align="center" :show-overflow-tooltip="false" :prop="getLanguageData({zh: 'name', en: 'name_en'})" :label="$t('bat_inquiry_dialog.name')" width="208">
                            <template slot-scope="scope">
                                <div class="toy-name">
                                    <el-image style="width: 48px; height: 36px; display: block; margin-left: 10px;" fit='contain' lazy :src="getImage(scope.row.main_picture)">
                                        <div slot="error">
                                            <i class="el-icon-picture-outline" style="font-size:36px;"></i>
                                        </div>
                                    </el-image>
                                    <div class="toy-name-text ellipsis" :title="getLanguageData({zh: scope.row.name, en: scope.row.name_en})">
                                        {{getLanguageData({zh: scope.row.name, en: scope.row.name_en})}}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center"  :show-overflow-tooltip="false" prop="article_number" :label="$t('bat_inquiry_dialog.article_number')" width="100">
                            <template slot-scope="scope">
                                <div class="ellipsis" :title="scope.row.article_number">{{scope.row.article_number}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center"  :show-overflow-tooltip="false" :prop="getLanguageData({zh: 'company_name', en: 'company_en_name'})" :label="$t('bat_inquiry_dialog.company_name')" width="108">
                            <template slot-scope="scope">
                                <div class="ellipsis" :title="getLanguageData({zh: scope.row.company_name, en: scope.row.company_en_name})">{{getLanguageData({zh: scope.row.company_name, en: scope.row.company_en_name})}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center"  :show-overflow-tooltip="false" prop="inquiry_count" :label="$t('bat_inquiry_dialog.inquiry_count')" width="92">
                            <template slot-scope="scope">
                                <el-form-item :prop="'tableData.' + scope.$index + '.inquiry_count'">
                                    <el-input-number class="input" style="width:100%" :min="0" :step="1" v-model="form.tableData[scope.$index].inquiry_count" :controls="false"></el-input-number>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column align="center"  :show-overflow-tooltip="false" prop="inquiry_content" :label="$t('bat_inquiry_dialog.remark')">
                            <template slot-scope="scope">
                                <el-form-item :prop="'tableData.' + scope.$index + '.inquiry_content'">
                                    <el-input class="input" v-model="form.tableData[scope.$index].inquiry_content" :maxlength="100"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" :label="$t('inquiry.operate')" width="82">
                            <template slot-scope="scope">
                                <div class="edit-button-group">
                                    <div class="operate-button operate-button" @click="del(scope.$index)">{{$t('bat_inquiry_dialog.remove')}}</div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="operate-button-group">
                    <div class="operate-button operate-button-1" @click="dialogVisible=false">{{$t('bat_inquiry_dialog.button1')}}</div>
                    <div class="operate-button operate-button-2" @click="save">{{$t('bat_inquiry_dialog.button2')}}</div>
                </div>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import { getLanguageData, isEmpty, deepCopy } from "@/libs/tools.js";
import { getLanguage } from "@/lang/index.js";
import { inquiry } from "@/api/inquiry.js";
import BatSetCountPopver from "./bat-set-count-popover.vue";
import BatSetRemarkPopver from "./bat-set-remark-popover.vue";

export default {
    name: 'bat-inquiry-dialog',
    components: {
        BatSetCountPopver,
        BatSetRemarkPopver,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },
    data() {
        return {
            lang: getLanguage(),
            form: {
                tableData: [],
            },
            loading: false,
            rules_inquiry_count: [
                { required: true, message: this.$t('bat_inquiry_dialog.fieldText1Required'), trigger: 'blur' },
            ],
            batSetCountPopverVisible: false,
            batSetRemarkPopverVisible: false,
        }
    },
    mounted() {

    },
    methods: {
        isEmpty,
        getLanguageData,
        //批量设置询价箱数
        batSetCount(count) {
            for (let i = 0; i < this.form.tableData.length; i++) {
                this.$set(this.form.tableData[i], 'inquiry_count', count);
            }
        },
        //批量设置备注
        batSetRemark(remark){
            for (let i = 0; i < this.form.tableData.length; i++) {
                this.$set(this.form.tableData[i], 'inquiry_content', remark);
            }
        },
        getImage(url) {
            if (url instanceof Object && JSON.stringify(url) == "{}") {
                return '';
            } else {
                return url;
            }
        },
        save() {
            if (this.form.tableData.length == 0) return false;    //空的不管他
            this.$refs.form.validate((valid, err) => {
                if (valid) {
                    let data = [];
                    for (let i = 0; i < this.form.tableData.length; i++) {
                        let item = this.form.tableData[i];
                        data.push({
                            cart_id: item['cart_id'],
                            toy_id: item['toy_id'],
                            inquiry_count: item['inquiry_count'],
                            inquiry_content: item['inquiry_content'],
                        });
                    }
                    inquiry({ inquiries: data }).then(res => {
                        if (res.code == 0) {
                            this.$message.success(res.msg);
                            this.$emit('refurbish');
                            this.dialogVisible = false;
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    for (let key in err) {
                        this.$message.error(err[key][0]['message']);
                        return false;
                    }
                }
            });
        },
        del(index) {
            this.form.tableData.splice(index, 1);
        },
        closeAll(){
            this.batSetRemarkPopverVisible=false;
            this.batSetCountPopverVisible=false;
        }
    },
    computed: {
        dialogVisible: {
            set(val) {
                this.$emit('input', val);
            },
            get() {
                return this.value;
            }
        },
    },
    watch: {
        dialogVisible: {
            immediate: true,
            handler: function (val) {
                if (val == true) {
                    this.$set(this.form, 'tableData', deepCopy(this.list));
                }else{
                    this.closeAll();
                }
            }
        },
    }
}
</script>
<style lang="less" scoped>
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/deep/ .el-form-item {
    margin-bottom: 0;
}
.inquiry-dialog {
    /deep/ .el-dialog {
        border-radius: 10px;
    }
    /deep/ .el-dialog__header {
        height: 49px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eaeaea;
        .el-dialog__title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
        }
    }
    /deep/ .el-dialog__body {
        padding: 0;
    }
    .inquiry-main {
        display: flex;
        flex-direction: column;
        margin: 0 24px;
        .button-group {
            margin-top: 16px;
            margin-bottom: 14px;
            display: flex;
            .button {
                background-color: #3053A3;
                color: #ffffff;
                padding: 7px 14px;
                margin-right: 14px;
                line-height: 16px;
                font-size: 12px;
                border-radius: 4px;
                cursor: pointer;
            }
        }
        .table {
            // margin-top: 16px;
            height: 340px;
        }
        .operate-button-group {
            margin-top: 16px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .operate-button {
                font-size: 14px;
                font-weight: 400;
                min-width: 100px;
                height: 32px;
                padding: 0;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .operate-button-1 {
                color: #999999;
                border: 1px solid #999999;
            }
            .operate-button-2 {
                margin-left: 16px;
                color: #ffffff;
                border: 1px solid #3053A3;
                background-color: #3053A3;
            }
        }
    }
    .input {
        /deep/ .el-input__inner {
            height: 26px;
            background: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 0px;
        }
    }
}
.table {
    .toy-name {
        display: flex;
        align-items: center;
        .toy-name-text {
            flex: 1;
            text-align: left;
            margin-left: 13px;
        }
    }
    .operate-button {
        background: rgba(207, 0, 13, 0.1);
        border-radius: 2px;
        color: #3053A3;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    /deep/ .table-header {
        background: #f2f2f2;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        height: 36px;
        border-color: #f2f2f2;
        .cell {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
    /deep/ .table-border {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        padding: 0;
        height: 50px;
        box-sizing: border-box;
        border-color: #e8e8e8;
    }
    /deep/ .operate-button-group {
        display: flex;
        justify-content: center;
        .operate-button {
            font-size: 12px;
            font-weight: 400;
            min-width: 68px;
            height: 24px;
            padding: 0;
            border-radius: 2px;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .operate-button-1 {
            color: #3053A3;
            border: 1px solid #3053A3;
        }
        .operate-button-2 {
            margin-left: 4px;
            color: #666666;
            border: 1px solid #666666;
        }
    }
    /deep/ .el-table--border {
        .el-table__cell:first-child {
            .cell {
                padding: 0;
            }
        }
    }
    /deep/ .el-input-number .el-input__inner {
        text-align: left;
    }
}
</style>
